body {
  font-family: 'Catamaran', sans-serif;
}
a {
  font-family: 'Catamaran', sans-serif;
  outline: 0;
}
img {
  max-width: 100%;
}
a {
  color: inherit;
  text-decoration: inherit;
}
a:hover {
  cursor: pointer;
}
.image {
  max-width: 100%;
  /*width: 98vw;*/
  cursor: pointer;
  transition: opacity 0.5s;
}
.image:hover {
  opacity: 0.5;
}
div.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: flex-start;
}
div.contact {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 60px;
}
a.email {
  /*font-weight: bold;*/
  color: black;
  text-decoration: underline;
}
.fullContainer {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}
.full {
  width: 98%;
}
.pruts {
  max-width: 90vw;
}
.description {
 font-size: 16px;
}
.right {
  transform: scaleX(-1);
  float: right;
}
.slide {
  background: white;
}
.directionButton {
  display: none;
  margin-left:4px;
  padding-bottom: 10px;
}
.footer {
  position: absolute;
  bottom: 0px;
  right:0px;
  width:20%;
}
html {
  min-height: 100vh;

}
body {
  min-height: 100vh;

  margin-top: 0px;
  margin-bottom: 0px;
}
#root {
  min-height: 100vh;

  position: relative;
}
.images {
  padding-bottom: 50px;
}
@media only screen and (min-width: 560px) {
  .images {
    margin-top: 80px;
    width:80%;
    margin-left: auto;
    margin-right: auto;
  }
  .image {
    width: 47%;
    height: 20vw;
    margin-bottom: 2vw;
    margin-left: 1vw;
    margin-right: 1vw;
  }
  .full {
    position: relative;
    max-width: 80vw;
    left: 50%;
    transform: translate(-50%,0);
  }
  .directionButton {
    display: inline;
  }
  .images {
    padding-bottom: 0px;
  }
  .footer {
    width:10%;
  }
}
.cv {
  padding-bottom: 50px;
}

@media only screen and (min-width: 2000px) {
  .images {
    margin-top: 80px;
    width:1590px;
    margin-left: auto;
    margin-right: auto;
  }
  .image {
    width: 755px;
    height: auto;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;

  }
}

@media only screen and (min-width: 800px) {
  .description {
    font-size: 26px;
  }
}

@media only screen and (orientation:landscape) {
  .pruts {
    max-width: 45vw;
  }
}
