div.statement {
    padding-left: 10%;
    padding-right: 10%;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    text-align: justify;
    margin-top: 60px;
    padding-bottom:50px;
}
