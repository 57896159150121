div.menu {
  font-family: sans-serif;
  font-size: 30px;
  margin-left:5%;
  margin-right:5%;
  width:100%;
  margin-top:25px;
  height: 190px;
}
a.topItemActive {
  font-weight: bold;
}
div.name {
  text-align:left;
}
div.item {
  text-align:right;
  font-size: 20px;
}
.subItem {
  display:none;
}

@media only screen and (min-width: 800px) {
  div.name {
    display: inline-block;
  }
  a.topItem {
    display:block;
    margin-bottom:10px;
  }
  a.subItem {
    font-size: 17px;
    display:block;
    color:grey;
    opacity: 1;
    transition: all 0.2s;
  }
  a.subItem.hide {
    transform: translateY(50px);
    opacity: 0;
    pointer-events:none;
  }
  a.subItemActive {
    font-weight: bold;
  }
  div.item {
    float:left;
    margin-left: 15px;
    height:100%;

  }
  .item a:hover {
    text-decoration: underline;
  }
  div.menuItems {
    float:right;
    height:100%;
  }
  div.menu {
    margin-bottom: 0px;
    height: 40px;
  }
}
