div.cv {
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}

h1 {
  font-size: 17px;
}

table {
  overflow-x:auto;
  margin-left: 15px;
}

td.date {
  width: 140px;
}

.cv p {
  margin: 0;
}

.cv div {
  margin-bottom: 20px;
}
